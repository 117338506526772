/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import $ from 'jquery';

window.jQuery = $;
window.$ = $;

require('./js/bootstrap.bundle.min.js');
require('./js/fslightbox.js');
require('./js/isotope.pkgd.js');

// any CSS you import will output into a single css file (app.css in this case)
import './css/bootstrap.css'
import './css/boxicon.min.css'
import './css/templatemo.css'
import './css/app.css';

$(window).load(function () {
    // init Isotope
    var $projects = $('.projects').isotope({
        itemSelector: '.project',
        layoutMode: 'fitRows'
    });
    $(".filter-btn").click(function () {
        var data_filter = $(this).attr("data-filter");
        $projects.isotope({
            filter: data_filter
        });
        $(".filter-btn").removeClass("active");
        $(".filter-btn").removeClass("shadow");
        $(this).addClass("active");
        $(this).addClass("shadow");
        return false;
    });
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
});
